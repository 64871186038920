import { DraggableInteraction } from './draggable_interaction.js'

export class DraggableInteractable {
  constructor (el, opts={}) {
    this.target = el
    this.opts = opts
    this.interactions = []
    this.callbacks = []
  }

  on (eventName, callback) {
    this.callbacks[eventName] = callback

    // lazy add event handlers
    switch (eventName) {
      case 'dragenter':
        this.listenToDragEvents()
        break
      case 'dragover':
        this.listenToDragEvents()
        break
      case 'dragleave':
        this.listenToDragEvents()
        break
      case 'drop':
        this.listenToDragEvents()
        break
      // case 'dragenter.native':
      //   this.listenToNativeDragEnter()
      //   break
      // case 'dragover.native':
      //   this.listenToNativeDragOver()
      //   break
      // case 'drop.native':
      //   this.listenToNativeDrop()
      //   break
      // case 'dragleave.native':
      //   this.listenToNativeDragLeave()
      //   break
    }

    return this
  }

  listenToDragEvents () {
    if (this.subscribedToDragEvents) { return }
    this.subscribedToDragEvents = true

    // This listens for other draggable interatables (and self potentially)
    let opts = { capture: true, passive: true }
    document.addEventListener('interactable-dragstart', (e) => { this.onDragStart(e) }, opts)
    document.addEventListener('interactable-drag', (e) => { this.onDrag(e) }, opts)
    document.addEventListener('interactable-dragend', (e) => { this.onDragEnd(e) }, opts)
  }

  onDragStart (event) {
    if (event.detail.target === this.target) { return }

    if (this.isHovering(event)) {
      this.isActive = true
      this.interactions.unshift(new DraggableInteraction(this.target, event))
      this.fire('dragover', { ...this.interactions[0] })
    }
  }

  onDrag (event) {
    if (event.detail.target === this.target) { return }

    if (this.isActive) {
      if (this.isHovering(event)) {
        this.interactions[0].addEvent(event)
        this.fire('dragover', { ...this.interactions[0] })
      } else {
        this.isActive = false
        this.interactions[0].addEvent(event)
        this.fire('dragleave', { ...this.interactions[0] })
      }
    } else {
      if (this.isHovering(event)) {
        this.isActive = true
        this.interactions.unshift(new DraggableInteraction(this.target, event))
        this.fire('dragenter', { ...this.interactions[0] })
      }
    }
  }

  onDragEnd (event) {
    if (event.detail.target === this.target) { return }

    if (this.isActive) {
      if (this.isHovering(event)) {
        this.interactions[0].addEvent(event)
        this.fire('drop', { ...this.interactions[0] })
      } else {
        this.interactions.unshift(new DraggableInteraction(this.target, event))
        this.fire('dragleave', { ...this.interactions[0] })
      }
    }
      this.isActive = false
  }

  isHovering (event) {
    const { detail: { lastX, lastY }} = event
    let rect = this.target.getBoundingClientRect()
    if ((rect.left + window.scrollX) <= lastX && (rect.right + window.scrollX) >= lastX) {
      if ((rect.top + window.scrollY) <= lastY && (rect.bottom + window.scrollY) >= lastY) {
        return true
      }
    }

    return false
  }

  fire (eventName, interaction) {
    let callback = this.callbacks[eventName]
    if (callback) {
      interaction.eventName = eventName
      callback(interaction, this)
    }
  }
}
