import uploadDB from '../upload_db.js'
import rand from './rand.js'

export default function storePendingUpload (file, parentId) {
  let pendingUpload = {
    cacheId: `uploads/${new Date().getTime()}-${rand(100, 1000)}`,
    file: file,
    name: file.name,
    type: file.type,
    size: file.size,
    createdAt: file.lastModified || Date.now(),
    parentId: parentId,
  }

  return uploadDB.setItem(pendingUpload.cacheId, pendingUpload)
    .then(() => { return pendingUpload })
}
