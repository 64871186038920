function indexBy (attribute, objects = []) {
  let index = {}

  objects.map((object) => {
    const key = object[attribute]
    if (key) {
      index[key] = object
    }
  })

  return index
}

export { indexBy }
