export default function flashController (messages = []) {
  return {
    show: false,
    messages: messages,
    addMessage (message) {
      this.messages.push(message)
    },
    dismissMessage (message) {
      this.messages.splice(this.messages.indexOf(message), 1)
    },
    init () {
      this.show = true
    }
  }
}
