export default function completeTaskController (task) {
  return {
    task: task,
    toggleComplete () {
      if (this.task.completed) {
        this.uncomplete()
      } else {
        this.complete()
      }
    },
    onCompleted (event) {
      if (this.task.id === event.detail.task.id) {
        this.complete()
      }
    },
    onUncompleted (event) {
      if (this.task.id === event.detail.task.id) {
        this.uncomplete()
      }
    },
    complete () {
      if (this.task.completed) { return }

      this.task.completed = true
      App.api.completeTask(this.task.id)
        .then(({ data }) => { App.dispatch("task:completed", { task: data.task }) })
        .catch((error) => { App.alert('Operation failed!', error); this.task.completed = false })
    },
    uncomplete () {
      if (!this.task.completed) { return }

      this.task.completed = false
      App.api.uncompleteTask(this.task.id)
        .then(({ data }) => { App.dispatch("task:uncompleted", { task: data.task }) })
        .catch((error) => { App.alert('Operation failed!', error);; this.task.completed = true })
    }
  }
}
