import axios from './axios.js'

export default {
  client: axios,

  deleteNodes (nodeIds) {
    return this.client.post('/api/v1/delete_nodes', { node_ids: nodeIds })
  },

  updateNode (nodeId, params) {
    return this.client.patch(`/api/v1/nodes/${nodeId}`, params)
  },

  createUpload (params) {
    return this.client.post('/api/v1/uploads', params)
  },

  createShare (params) {
    return this.client.post('/api/v1/shares', params)
  },

  completeTask (id) {
    return this.client.post(`/api/v1/tasks/${id}/complete`)
  },

  uncompleteTask (id) {
    return this.client.post(`/api/v1/tasks/${id}/uncomplete`)
  },

  archiveTask (id) {
    return this.client.post(`/api/v1/tasks/${id}/archive`)
  },

  unarchiveTask (id) {
    return this.client.post(`/api/v1/tasks/${id}/unarchive`)
  },

  sortTask (id, params) {
    return this.client.post(`/api/v1/tasks/${id}/sort`, params)
  },

  snooze (id, dateTime) {
    return this.client.post(`/api/v1/tasks/${id}/snooze`, { snooze_until: dateTime })
  }

  // ntp (params) {
  //   return this.client.get('/ntp', params)
  // }
}
