import { TouchInteraction } from './touch_interaction.js'

export class TouchInteractable {
  constructor(el, opts={}) {
    this.target = el
    this.opts = opts
    this.interactions = []
    this.callbacks = []

    this.maxTapDelta = this.fetchOption('maxTapDelta', 10)
    this.maxTapDuration = this.fetchOption('maxTapDuration', 500)
    this.maxDoubleTapDuration = this.fetchOption('maxDoubleTapDuration', 500)
    this.longTapDuration = this.fetchOption('longTapDuration', 800)
    this.minTouchDragDelta = this.fetchOption('minTouchDragDelta', this.maxTapDelta)
  }

  on (eventName, callback) {
    this.callbacks[eventName] = callback

    // lazy add event handlers
    switch (eventName) {
      case 'tap':
        this.listenToTouch()
        break
      case 'dbltap':
        this.listenToDblTap()
        break
      case 'longtap':
        this.listenToLongTap()
        break
      case 'touchdragstart':
        this.listenToTouchDragStart()
        break
      case 'touchdrag':
        this.listenToTouchDrag()
        break
      case 'touchdragend':
        this.listenToTouchDragEnd()
        break
    }

    return this
  }

  listenToTouch () {
    if (this.subscribedToTouch) { return }
    this.subscribedToTouch = true

    this.target.addEventListener('touchstart', (e) => { this.onTouchStart(e) })
    this.target.addEventListener('touchend', (e) => { this.onTouchEnd(e) })
    this.target.addEventListener('touchcancel', (e) => { this.onTouchCancel(e) })
    this.target.addEventListener('click', (e) => {
      // Is this click event related to a touch interaction?
      if (this.interactions[0] && this.interactions[0].interacting) {
        e.preventDefault()
      }
    })
  }

  listenToDblTap () {
    if (this.subscribedToDblTap) { return }
    this.subscribedToDblTap = true

    this.listenToTouch()
  }

  listenToLongTap () {
    if (this.subscribedToLongTap) { return }
    this.subscribedToLongTap = true

    this.listenToTouch()
  }

  listenToTouchDragStart () {
    if (this.subscribedToTouchDragStart) { return }
    this.subscribedToTouchDragStart = true

    this.listenToTouch()

    this.target.addEventListener('touchmove', (e) => { this.onTouchMove(e) })
    // this.target.addEventListener('dragstart', (e) => { e.preventDefault() })
  }

  listenToTouchDrag () {
    if (this.subscribedToTouchDrag) { return }
    this.subscribedToTouchDrag = true

    this.listenToTouchDragStart()
  }

  listenToTouchDragEnd () {
    if (this.subscribedToTouchDragEnd) { return }
    this.subscribedToTouchDragEnd = true

    this.listenToTouchDragStart()
  }


  onTouchStart (event) {
    // event.preventDefault()

    let interaction = new TouchInteraction(this.target, event)
    this.interactions.unshift(interaction)

    if (this.subscribedToLongTap) {
      this.longTapTimer = setTimeout(() => {
        this.fire('longtap', { ...interaction })
      }, this.longTapDuration)
    }
  }

  onTouchMove (event) {
    // event.preventDefault()

    this.interactions[0].addMoveEvent(event)

    if (this.subscribedToTouchDragStart && !this.touchDragStarted && this.wasTouchDragged(this.interactions[0])) {
      this.startTouchDrag()
    } else if (this.subscribedToTouchDrag && this.touchDragStarted) {
      this.fire('touchdrag', { ...this.interactions[0] })
      this.dispatch('interactable-drag', { ...this.interactions[0] })
    } else if (!this.touchDragStarted && this.shouldClearLongTapTimer(this.interactions[0])) {
      if (this.longTapTimer) {
        clearTimeout(this.longTapTimer)
        this.longTapTimer = null
      }
    }
  }

  onTouchEnd (event) {
    this.interactions[0].addEndEvent(event)

    if (this.subscribedToLongTap) { clearTimeout(this.longTapTimer) }

    if (this.subscribedToTouchDragEnd && this.touchDragStarted) {
      this.endTouchDrag()
    } else if (this.subscribedToDblTap && !this.touchDragStarted && this.wasDblTapped(this.interactions)) {
      this.fire('dbltap', { ...this.interactions[0] })
    } else if (this.subscribedToTouch && !this.touchDragStarted && this.wasTapped(this.interactions[0])) {
      this.fire('tap', { ...this.interactions[0] })
    }

    if (this.touchDragStarted) { this.touchDragStarted = false }
  }

  onTouchCancel (event) {
    if (this.touchDragStarted) { this.touchDragStarted = false }
    if (this.subscribedToLongTap) { clearTimeout(this.longTapTimer) }
  }

  wasTapped (interaction){
    if (!interaction) { return false }
    if (interaction.delta > this.maxTapDelta) { return false }
    if (interaction.duration > this.maxTapDuration) { return false }

    return true
  }

  wasDblTapped (interactions) {
    if (!this.wasTapped(interactions[0])) { return false }
    if (!this.wasTapped(interactions[1])) { return false }

    let timeBetweenTaps = interactions[0].start - (interactions[1].start + interactions[1].duration)
    return timeBetweenTaps < this.maxDoubleTapDuration
  }

  wasTouchDragged (interaction) {
    if (!interaction) { return false }

    return interaction.delta >= this.minTouchDragDelta
  }

  shouldClearLongTapTimer (interaction) {
    if (!interaction) { return false }

    return this.subscribedToLongTap && interaction.delta > this.maxTapDelta
  }

  startTouchDrag () {
    if (this.subscribedToLongTap) { clearTimeout(this.longTapTimer) }
    this.touchDragStarted = true
    this.fire('touchdragstart', { ...this.interactions[0] })
    this.dispatch('interactable-dragstart', { ...this.interactions[0] })
  }

  endTouchDrag () {
    if (this.touchDragStarted) { this.touchDragStarted = false }
    this.fire('touchdragend', { ...this.interactions[0] })
    this.dispatch('interactable-dragend', { ...this.interactions[0] })
  }

  fire (eventName, interaction) {
    let callback = this.callbacks[eventName]
    if (callback) {
      interaction.eventName = eventName
      callback(interaction, this)
    }
  }

  dispatch (eventName, interaction) {
    document.dispatchEvent(new CustomEvent(eventName, { detail: interaction }))
  }

  fetchOption (option, defaultValue) {
    if (this.opts[option] === undefined) { return defaultValue }

    return this.opts[option]
  }
}
