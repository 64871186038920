export default function archiveTaskController (task) {
  return {
    task: task,
    unarchive () {
      if (!this.task.archived) { return }

      this.task.archived = false
      App.api.unarchiveTask(this.task.id)
        .then(({ data }) => { App.dispatch("task:unarchived", { task: data.task }) })
        .catch((error) => { App.alert('Operation failed!', error); this.task.archived = true })
    },
    onArchived (event) {
      if (this.task.id === event.detail.task.id) {
        this.task.archived = true
      }
    },
    onUnarchived (event) {
      if (this.task.id === event.detail.task.id) {
        this.task.archived = false
      }
    }
  }
}
