import uploadDB from '../upload_db.js'
import { DirectUpload } from '@rails/activestorage'

export default function uploadStatusController () {
  return {
    show: false,
    status: 'pending',
    success: [],
    failed: [],
    pendingUploads: [],
    currentPercent: 0,
    init () {
      this.load()
    },
    load () {
      return uploadDB.iterate((value, key, iterationNumber) => { this.pendingUploads.push(value) })
        .then(() => { this.start() })
        .catch((error) => { App.alert(error) })
    },
    async start () {
      if (this.pendingUploads.length === 0) { return }

      this.show = true

      while (this.pendingUploads.length > 0) {
        this.currentUpload = this.pendingUploads.shift()
        this.currentPercent = 0

        await this.upload(this.currentUpload)

        this.success.push(this.currentUpload)
        this.currentUpload = null
      }

      window.location.reload(true)
      this.show = false
    },

    total () {
      return this.pending() + this.processed() + this.inProgress()
    },

    pending () {
      return this.pendingUploads.length
    },

    processed () {
      return this.success.length + this.failed.length
    },

    inProgress () {
      return this.currentUpload ? 1 : 0
    },

    async upload (pendingUpload) {
      return this.directUpload(pendingUpload)
        .then((uploadWithBlob) => { return this.createUpload(uploadWithBlob) })
        .then ((response) => { uploadDB.removeItem(pendingUpload.cacheId) })
    },
    directUpload (pendingUpload) {
      const directUpload = new DirectUpload(pendingUpload.file, '/rails/active_storage/direct_uploads', this)

      return new Promise((resolve, reject) => {
        // This doesn't return a promise
        directUpload.create((error, blob) => {
          if (error) {
            reject(error)
          } else {
            pendingUpload.file_signed_id = blob.signed_id
            this.currentPercent = 100
            resolve(pendingUpload)
          }
        })
      })
    },
    createUpload (pendingUpload) {
      return App.api.createUpload({
        file: pendingUpload.file_signed_id,
        name: pendingUpload.name,
        parent_id: pendingUpload.parentId,
        created_at: new Date(pendingUpload.createdAt)
      })
    },

    directUploadWillStoreFileWithXHR (request) {
      request.upload.addEventListener('progress', (event) => {
        this.directUploadDidProgress(event)
      })
    },
    directUploadDidProgress (event) {
      this.currentPercent = (event.loaded/event.total * 100).toFixed(2) - 1
    }
  }
}
