function sortBy (collection, attribute, direction = 'desc') {
  return [...collection].sort((a, b) => {
    if (direction === 'desc') {
      if (b[attribute] > a[attribute]) {
        return 1
      } else if (a[attribute] > b[attribute]) {
        return -1
      } else {
        return 0
      }
    } else {
      if (a[attribute] > b[attribute]) {
        return 1
      } else if (b[attribute] > a[attribute]) {
        return -1
      } else {
        return 0
      }
    }
  })
}

export { sortBy }
