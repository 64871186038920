export default function nodesController () {
  return {
    selectedNodes: [],
    nodeEls: [],
    init () {
      let nodes = this.$root.querySelectorAll('.node[data-node-id]')
      this.nodeEls = Array.from(nodes).map((n) => { return n.dataset.nodeId })
    },
    toggleSelectAll (event) {
      if (event.target.checked) {
        this.selectAll()
      } else {
        this.deselectAll()
      }
    },
    selectAll () {
      let checkboxes = this.$root.querySelectorAll('[data-node-checkbox-id]')
      for (var i=0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true
      }

      this.selectedNodes = this.nodeEls
    },
    deselectAll () {
      let checkboxes = this.$root.querySelectorAll('[data-node-checkbox-id]')
      for (var i=0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false
      }

      this.selectedNodes = []
    },
    toggleSelected (event) {
      if (event.detail.checked) {
        this.selectNode(event.detail.nodeId)
      } else {
        this.deselectNode(event.detail.nodeId)
      }
    },
    selectNode (nodeId) {
      if (this.selectedNodes.includes(nodeId)) { return }

      this.selectedNodes.push(nodeId)
    },
    deselectNode (nodeId) {
      if (!this.selectedNodes.includes(nodeId)) { return }

      this.selectedNodes.splice(this.selectedNodes.indexOf(nodeId), 1)
    },
    deleteSelectedNodes (event) {
      if (!confirm("Are you sure you want to delete the selected item(s)?")) { return }

      App.api.deleteNodes(this.selectedNodes)
        .then((response) => { window.location.reload(true) })
        .catch((error) => { App.alert(error) })
    },
    downloadSelectedNodes (event) {
      App.api.downloadNodes(this.selectedNodes)
        .catch((error) => { App.alert(error) })
    },
    nodeMoved (event) {
      let nodeId = event.detail.nodeId
      let node = document.querySelector(`.node[data-node-id='${nodeId}']`)
      if (node) {
        node.parentNode.removeChild(node)
        this.deselectAll()
      }
    }
  }
}
