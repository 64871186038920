/*
This is the API I want I think:
  - opts should be the way to set configuration variables like min traversal
    distance for drag or min time to long click

  - Do I need to know about other "interactables"?
    That might help for the dragenter dragover and drop events. It also could be
    done by attributes/data attributes?

  - Do I care to "hook" into native events (drag n drop etc.), or am I saying:
    "You have to use this on all elements that need to interact together"?


let interactable = new Interactable(el, opts={})
  .on('click', (interaction, event) => {})
  .on('dblclick', (interaction, event) => {})
  .on('longclick', (interaction, event) => {})

  .on('tap', (interaction, event) => {})
  .on('dbltap', (interaction, event) => {})
  .on('longtap', (interaction, event) => {})

  .on('dragstart', (interaction, event) => {})
  .on('drag', (interaction, event) => {})
  .on('dragend', (interaction, event) => {})

  .on('dragenter', (interaction, event) => {})
  .on('dragover', (interaction, event) => {})
  .on('drop', (interaction, event) => {})
*/

import { MouseInteractable } from './interactable/mouse_interactable.js'
import { TouchInteractable } from './interactable/touch_interactable.js'
import { DraggableInteractable } from './interactable/draggable_interactable.js'

export class Interactable {
  constructor (el, opts={}) {
    this.target = el
    this.opts = opts
    this.callbacks = []
    this.interactions = []
    this.providers = this.buildProviders()
  }

  on (eventName, callback) {
    this.providers.map((p) => { p.on(eventName, callback) })

    return this
  }

  buildProviders () {
    let providers = this.opts.providers || [MouseInteractable, TouchInteractable, DraggableInteractable]
    return providers.map((p) => { return new p(this.target, this.opts) })
  }
}

