import Dispatch from '../dispatch.js'
import { Interactable } from '../interactable.js'
import createDraggableImage from '../utils/create_draggable_image.js'

export default function uploadController () {
  return {
    previewLoading: false,
    toggleSelected (event) {
      new Dispatch(this.$root)
        .dispatch('toggle-selected', { nodeId: this.$root.dataset.nodeId, checked: event.target.checked } )
    },

    init () {
      this.interactable = new Interactable(this.$refs.dragHandle, { minTouchDragDelta: 9999 })
        .on('click', (e) => { window.location.href = `/view/${this.$root.dataset.nodeId}` })
        .on('tap', (e) => { window.location.href = `/view/${this.$root.dataset.nodeId}` })
        .on('longtap', (e, provider) => { provider.startTouchDrag() })
        .on('mousedragstart', (e) => { this.onDragStart(e) })
        .on('mousedrag', (e) => { this.onDrag(e) })
        .on('mousedragend', (e) => { this.onDragEnd(e) })
        .on('touchdragstart', (e) => { this.onDragStart(e) })
        .on('touchdrag', (e) => { this.onDrag(e) })
        .on('touchdragend', (e) => { this.onDragEnd(e) })
    },

    onDragStart (e) {
      this.$root.classList.add('dragging','bg-gray-200')
      this.dragImage = createDraggableImage(this.$root)
      this.dragImage.style.position = 'absolute'
      this.dragImage.style.top = '0px'
      this.dragImage.style.left = '0px'
      this.dragImage.style.transform = `translate(-99999px, -99999px)`
      document.body.appendChild(this.dragImage)
    },

    onDrag (e) {
      this.moveDragImage(e)
    },

    onDragEnd (e) {
      this.$root.classList.remove('dragging', 'bg-gray-200')
      document.body.removeChild(this.dragImage)
    },

    moveDragImage (e) {
      requestAnimationFrame (() => {
        if (this.dragImage) {
          let rects = this.$root.getBoundingClientRect()
          this.dragImage.style.pointerEvents = 'none'
          this.dragImage.style.zIndex = '99999'
          let x = Math.round(rects.left + window.scrollX + e.deltaX)
          let y = Math.round(rects.top + window.scrollY + e.deltaY)
          this.dragImage.style.transform = `translate(${x}px, ${y}px)`
        }
      })
    },
  }
}

// export default class UploadController {
//   constructor (el) {
//     this.el = el
//     this.interactable = new Interactable(el)
//       .on('click', (e) => { Turbolinks.visit(`/view/${this.el.dataset.nodeId}`) })
//       .on('tap', (e) => { Turbolinks.visit(`/view/${this.el.dataset.nodeId}`) })
//       .on('mousedragstart', (e) => { this.onDragStart(e) })
//       .on('mousedrag', (e) => { this.onDrag(e) })
//       .on('mousedragend', (e) => { this.onDragEnd(e) })
//       .on('touchdragstart', (e) => { this.onDragStart(e) })
//       .on('touchdrag', (e) => { this.onDrag(e) })
//       .on('touchdragend', (e) => { this.onDragEnd(e) })
//   }

//   onDragStart (e) {
//     this.el.classList.add('dragging','bg-gray-200')
//     this.dragImage = createDraggableImage(this.el)
//     this.dragImage.style.position = 'absolute'
//     this.dragImage.style.top = '0px'
//     this.dragImage.style.left = '0px'
//     this.dragImage.style.transform = `translate(-99999px, -99999px)`
//     document.body.appendChild(this.dragImage)
//   }

//   onDrag (e) {
//     this.moveDragImage(e)
//   }

//   onDragEnd (e) {
//     this.el.classList.remove('dragging', 'bg-gray-200')
//     document.body.removeChild(this.dragImage)
//   }

//   moveDragImage (e) {
//     requestAnimationFrame (() => {
//       if (this.dragImage) {
//         let rects = this.el.getBoundingClientRect()
//         this.dragImage.style.pointerEvents = 'none'
//         this.dragImage.style.zIndex = '99999'
//         let x = Math.round(rects.left + e.deltaX)
//         let y = Math.round(rects.top + e.deltaY)
//         this.dragImage.style.transform = `translate(${x}px, ${y}px)`
//       }
//     })
//   }
// }
