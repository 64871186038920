import Alpine from '../alpine.js'
import Dispatch from '../dispatch.js'
import { Interactable } from '../interactable.js'

Alpine.data('task_preview', (task) => ({
  task: task,
  isScrolling: false,
  isSorting: false,
  translateOffset: 0,
  init () {
    this.minTranslate = this.$refs.taskEl.offsetWidth * -1
    this.maxTranslate = this.$refs.taskEl.offsetWidth
    this.interactable = new Interactable(this.$refs.taskEl)
      .on('touchdragstart', (e) => { this.onDragStart(e) })
      .on('touchdrag', (e) => { this.onDrag(e) })
      .on('touchdragend', (e) => { this.onDragEnd(e) })
  },

  onDragStart (e) {
    if ((this.$refs.dragHandle == e.initialEvent.target || this.$refs.dragHandle.contains(e.initialEvent.target))) {
      this.isSorting = true
      return
    } else if (Math.abs(e.deltaY) > Math.abs(e.deltaX)) {
      this.isScrolling = true
      return
    } else {
      document.body.style = "overflow: hidden;"
    }
    this.translate(e)
  },

  onDrag (e) {
    if (this.isScrolling || this.isSorting) { return }
    this.translate(e)
  },

  onDragEnd (e) {
    if (this.isScrolling || this.isSorting) {
      this.isScrolling = false
      this.isSorting = false
      document.body.style = "overflow: auto;"
      return
    }

    if (e.deltaX > 120) {
      this.swipeRight(e)
    } else if (e.deltaX < -120) {
      this.swipeLeft(e)
    } else {
      this.snapTo(this.translateOffset)
    }
  },

  translate (e) {
    let translation = Math.max(this.minTranslate, Math.min(e.deltaX + this.translateOffset, this.maxTranslate))
    this.$refs.taskEl.style=`transform: translateX(${translation}px);`
  },

  swipeRight (e) {
    // Snooze section is already visible
    if (this.translateOffset > 0) { return }

    if (this.translateOffset === 0) {
      this.snapTo(this.maxTranslate)
    }
  },

  swipeLeft (e) {
    // Snooze section is showing
    if (this.translateOffset > 0) {
      this.snapTo(0)
    } else {
      this.archive(this.minTranslate)
    }
  },

  snapTo(position) {
    this.$refs.taskEl.style = `
        transition-property: transform;
        transition-duration: 75ms;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transform: translateX(${position}px);
      `
    this.translateOffset = position
  },

  archive (position) {
    App.api.archiveTask(this.task.id)
      .catch((error) => { console.log(error) })

    this.snapTo(position)
    setTimeout(() => { this.$root.parentNode.removeChild(this.$root) }, 400)
  },

  snooze (duration) {
    let snoozeUntil

    switch (duration) {
      case '1 hour':
        snoozeUntil = new Date()
        snoozeUntil.setHours(snoozeUntil.getHours() + 1)
        break
      case '1 day':
        snoozeUntil = new Date()
        snoozeUntil.setDate(snoozeUntil.setDate() + 1)
        break
      case '1 week':
        snoozeUntil = new Date()
        snoozeUntil.setDate(snoozeUntil.setDate() + 7)
        break
    }

    if (snoozeUntil) {
      App.api.snooze(this.task.id, snoozeUntil)
        .catch((error) => { console.log(error) })

      this.snapTo(0)
      setTimeout(() => { this.$root.parentNode.removeChild(this.$root) }, 400)
    }
  }
}))
