import Alpine from 'alpinejs'
import { indexBy } from './utils/index_by.js'


Alpine.magic('root', el => {
  let closestRootEl = (node) => {
    if (node.hasAttribute('x-data')) { return node }
    return closestRootEl(node.parentNode)
  }

  return closestRootEl(el)
})

export default Alpine
