export class DraggableInteraction {
  constructor (target, event) {
    let relatedInteraction = event.detail

    this.source = 'interactable'
    this.target = target
    this.relatedInteraction = relatedInteraction
    this.initialEvent = event
    this.startX = relatedInteraction.lastX
    this.startY = relatedInteraction.lastY
    this.lastX = relatedInteraction.lastX
    this.lastY = relatedInteraction.lastY
    this.deltaX = 0
    this.deltaY = 0
    this.delta = 0
    this.duration = 0
    this.start = performance.now()
    this.startTime = Date.now()
  }

  addEvent (event) {
    let relatedInteraction = event.detail

    this.lastX = relatedInteraction.lastX
    this.lastY = relatedInteraction.lastY
    this.deltaX = Math.round(this.lastX - this.startX)
    this.deltaY = Math.round(this.lastY - this.startY)
    this.delta = Math.round(Math.sqrt(Math.pow(this.deltaX, 2) + Math.pow(this.deltaY, 2)))
    this.duration = performance.now() - this.start
  }
}
