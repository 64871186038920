require("@rails/ujs").start()
require("@rails/activestorage").start()

import Alpine from './src/alpine.js'
window.Alpine = Alpine

import { directive } from 'livewire-rails'
Alpine.plugin(directive)


import { Router } from './src/router.js'
window.Router = Router

import './src/components/seed.js'
import './src/components/task_sorting.js'
import './src/components/task_preview.js'
import './src/components/quill.js'

import './src/stores/lists.js'
import './src/stores/tasks.js'

import Dispatch from './src/dispatch.js'
import api from './src/api.js'
import uploadDB from './src/upload_db.js'
import flashController from './src/controllers/flash_controller.js'
import nodesController from './src/controllers/nodes_controller.js'
import uploadController from './src/controllers/upload_controller.js'
import folderController from './src/controllers/folder_controller.js'
import locationController from './src/controllers/location_controller.js'
import uploadActionController from './src/controllers/upload_action_controller.js'
import uploadStatusController from './src/controllers/upload_status_controller.js'
import completeTaskController from './src/controllers/complete_task_controller.js'
import archiveTaskController from './src/controllers/archive_task_controller.js'
// import taskPreviewController from './src/controllers/task_preview_controller.js'
// import taskSortingController from './src/controllers/task_sorting_controller.js'
import markdownInputController from './src/controllers/markdown_input_controller.js'

const App = {
  api: api,
  uploadDB: uploadDB,
  dispatch: (event, detail) => { Dispatch.dispatch(event, detail) },
  flashController,
  nodesController,
  uploadController,
  folderController,
  locationController,
  uploadActionController,
  uploadStatusController,
  completeTaskController,
  archiveTaskController,
  markdownInputController,
  alert (title, details='') {
    this.dispatch('add-message', { type: 'alert', title: title, details: details })
  },
  notice (title, details='') {
    this.dispatch('add-message', { type: 'notice', title: title, details: details })
  },
  start: () => {
    Alpine.start()
    Router.start()
  }
}

window.App = App
document.addEventListener('DOMContentLoaded', () => {
  App.start()
})
