export default class Dispatch {
  constructor(el) {
    this.target = el
  }

  static dispatch (event, detail = {}) {
    return new this(document).dispatch(event, detail)
  }

  dispatch (event, detail = {}) {
    return this.target.dispatchEvent(new CustomEvent(event, { detail, bubbles: true }))
  }
}
