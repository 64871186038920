import Alpine from '../alpine.js'

Alpine.data('quill', () => ({
  init() {
    let quill = new window.Quill(this.$refs.quill, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': 1 }, { 'header': 2 }],
          ['bold', 'italic', 'strike', 'underline', 'link', 'code-block'],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          ['clean']
        ]
      }
    })

    quill.root.innerHTML = this.$refs.bodyInput.value

    quill.on('text-change', () => {
      this.$refs.bodyInput.value = quill.root.innerHTML
    })
  }
}))
