import Alpine from '../alpine.js'
import { indexBy } from '../utils/index_by.js'
import { sortBy } from '../utils/sort_by.js'

Alpine.store('lists', {
  index: {},
  all: [],
  selectedId: null,
  selectedList: null,
  showForm: false,
  init () {
    Alpine.effect(() => {
      this.all = Object.values(this.index)
    })
  },
  import (lists, selectedId = null, showForm = false) {
    this.index = indexBy('id', lists)
    this.selectedId = selectedId
    this.selectedList = this.index[this.selectedId]
    this.showForm = showForm
  },
  setState ({ selectedId, showForm }) {
    this.selectedId = selectedId
    this.selectedList = this.index[this.selectedId]
    this.showForm = showForm
  },
  sortBy (attribute, direction = 'desc') {
    return sortBy(this.all, attribute, direction)
  }
})
