export default function markdownInputController () {
  return {
    cursorStart: 0,
    cursorEnd: 0,
    keyup () {
      let input = this.$refs.input
      this.cursorStart = input.selectionStart
      this.cursorEnd = input.selectionEnd
      input.style.height = 'auto'
      input.style.height = `${input.scrollHeight + 20}px`
    },

    insertTitle () {
      const lineStartPosition = this.lineStart()
      const textBeforeLine = this.$refs.input.value.substring(0, lineStartPosition)
      const textAfterLine = this.$refs.input.value.substring(lineStartPosition)
      this.$refs.input.value = `${textBeforeLine}# ${textAfterLine}`
      this.$refs.input.focus()
      this.$refs.input.selectionEnd = this.cursorEnd + 2
    },

    insertSubTitle () {
      const lineStartPosition = this.lineStart()
      const textBeforeLine = this.$refs.input.value.substring(0, lineStartPosition)
      const textAfterLine = this.$refs.input.value.substring(lineStartPosition)
      this.$refs.input.value = `${textBeforeLine}## ${textAfterLine}`
      this.$refs.input.focus()
      this.$refs.input.selectionEnd = this.cursorEnd + 3
    },

    insertBold () {
      const textBeforeStart = this.$refs.input.value.substring(0, this.cursorStart)
      const textSelected = this.$refs.input.value.substring(this.cursorStart, this.cursorEnd)
      const textAfterEnd = this.$refs.input.value.substring(this.cursorEnd)
      this.$refs.input.value = `${textBeforeStart}**${textSelected}**${textAfterEnd}`
      this.$refs.input.focus()
      this.$refs.input.selectionEnd = this.cursorEnd + 2
    },

    insertBulletList () {
      const lineStartPosition = this.lineStart()
      const textBeforeLine = this.$refs.input.value.substring(0, lineStartPosition)
      const textAfterLine = this.$refs.input.value.substring(lineStartPosition)
      this.$refs.input.value = `${textBeforeLine}- ${textAfterLine}`
      this.$refs.input.focus()
      this.$refs.input.selectionEnd = this.cursorEnd + 2
    },

    insertNumberedList () {
      const lineStartPosition = this.lineStart()
      const textBeforeLine = this.$refs.input.value.substring(0, lineStartPosition)
      const textAfterLine = this.$refs.input.value.substring(lineStartPosition)
      this.$refs.input.value = `${textBeforeLine}1. ${textAfterLine}`
      this.$refs.input.focus()
      this.$refs.input.selectionEnd = this.cursorEnd + 3
    },

    lineStart () {
      const lookbackCharacters = this.$refs.input.value
        .substring(0, this.cursorStart)
        .split("\n")
        .pop()
        .length

      return this.cursorStart - lookbackCharacters
    }
  }
}
