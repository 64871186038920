export class MouseInteraction {
  constructor (target, event) {
    this.source = 'mouse'
    this.target = target
    this.initialEvent = event
    this.lastEvent = event
    this.startX = event.pageX
    this.startY = event.pageY
    this.lastX = event.pageX
    this.lastY = event.pageY
    this.deltaX = 0
    this.deltaY = 0
    this.delta = 0
    this.duration = 0
    this.start = performance.now()
    this.startTime = Date.now()
    this.interacting = true
  }

  addEvent (event) {
    this.lastX = event.pageX
    this.lastY = event.pageY
    this.deltaX = Math.round(event.pageX - this.startX)
    this.deltaY = Math.round(event.pageY - this.startY)
    this.delta = Math.round(Math.sqrt(Math.pow(this.deltaX, 2) + Math.pow(this.deltaY, 2)))
    this.lastEvent = event
    this.duration = performance.now() - this.start
  }

  addFinalEvent (event) {
    this.addEvent(event)
    this.interacting = false
  }
}

