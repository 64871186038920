const TASK_REGEX = /\/tasks\/(\d+|new)\/?(edit)?/g
const LIST_REGEX = /\/lists\/(\d+|new)\/?(edit)?.*/g

//m = /\/tasks\/(\d+|new)\/?(edit)?/g.exec('/lists/23/tasks/23/edit')
//  Array(3) [ "/tasks/23/edit", "23", "edit" ]

const Router = {
  start () {
    const path = window.location.pathname
    const state = this.parseState(path)
    window.history.replaceState(state, '', path)

    window.addEventListener("popstate", ({ state }) => {
      if (state) { this.setState(state) }
    })
  },

  visit (path) {
    const state = this.parseState(path)
    this.setState(state)
    window.history.pushState(state, '', path)
  },

  parseState (path) {
    return {
      lists: this.parseModelState(path, LIST_REGEX),
      tasks: this.parseModelState(path, TASK_REGEX)
    }
  },

  parseModelState (path, regex) {
    let match = new RegExp(regex).exec(path)
    if (!match) { return {}}

    if (match[1] === 'new') {
      return { selectedId: null, showForm: true }
    } else if (match[1] && match[2] === 'edit') {
      return { selectedId: parseInt(match[1]), showForm: true }
    } else if (match[1]) {
      return { selectedId: parseInt(match[1]), showForm: false }
    } else {
      return { selectedId: null, showForm: false }
    }
  },

  setState (state) {
    Alpine.store('tasks').setState(state.tasks)
    Alpine.store('lists').setState(state.lists)
  }
}

export { Router }

