export class TouchInteraction {
  // How do we support multi touch (pinch, zoom, etc.)?
  constructor (target, event) {
    this.source = 'touch'
    this.target = target
    this.initialEvent = event
    this.touches = event.touches
    this.startX = event.touches[0].pageX
    this.startY = event.touches[0].pageY
    this.lastX = event.touches[0].pageX
    this.lastY = event.touches[0].pageY
    this.deltaX = 0
    this.deltaY = 0
    this.delta = 0
    this.duration = 0
    this.start = performance.now()
    this.startTime = Date.now()
    this.interacting = true
  }

  addMoveEvent (event) {
    this.lastX = event.touches[0].pageX
    this.lastY = event.touches[0].pageY
    this.deltaX = Math.round(this.lastX - this.startX)
    this.deltaY = Math.round(this.lastY - this.startY)
    this.delta = Math.round(Math.sqrt(Math.pow(this.deltaX, 2) + Math.pow(this.deltaY, 2)))
    this.duration = performance.now() - this.start
  }

  addEndEvent (event) {
    this.lastX = event.changedTouches[0].pageX
    this.lastY = event.changedTouches[0].pageY
    this.deltaX = Math.round(this.lastX - this.startX)
    this.deltaY = Math.round(this.lastY - this.startY)
    this.delta = Math.round(Math.sqrt(Math.pow(this.deltaX, 2) + Math.pow(this.deltaY, 2)))
    this.duration = performance.now() - this.start
    this.interacting = false
  }
}
