import { Droppable } from '../droppable.js'
import { Draggable } from '../draggable.js'
import { Interactable } from '../interactable.js'
import api from '../api.js'

export default function locationController () {
  return {
    location: null,
    init () {
      this.interactable = new Interactable(this.$root)
        .on('dragenter', (e) => { this.$root.classList.add('hovered') })
        .on('dragleave', (e) => { this.$root.classList.remove('hovered') })
        .on('drop', (e) => { this.onDrop(e) })
    },

    onDrop (e) {
      this.$root.classList.remove('hovered')
      let nodeId = e.relatedInteraction.target.dataset.nodeId
      let parentId = this.$root.dataset.nodeId
      App.api.updateNode(nodeId, { parent_id: parentId })
        .then((response) => { App.dispatch('node-moved', { nodeId }) })
        .catch((error) => { App.alert(error) })
    }
  }
}
