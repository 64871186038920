import storePendingUpload from '../utils/store_pending_upload.js'

export default function uploadActionController (nodeId) {
  return {
    parentId: nodeId,
    uploadFiles (event) {
      let files = event.target.files

      let promises = []
      for (var i=0; i < files.length; i++) {
        promises.push(storePendingUpload(files[i], this.parentId))
      }

      Promise.all(promises)
        .then(() => { App.dispatch('pending-upload-stored') })
        .catch((error) => { App.alert(error) })
    }
  }
}
