import axios from 'axios'
import Rails from '@rails/ujs'

const instance = axios.create({ headers: { 'Accept': 'application/json' }})

instance.interceptors.request.use((config) => {
  if (config.method !== 'get') {
    config.headers['X-CSRF-Token'] = Rails.csrfToken()
  }

  return config
})

export default instance
