import Dispatch from '../dispatch.js'
import { Interactable } from '../interactable.js'
import createDraggableImage from '../utils/create_draggable_image.js'
import api from '../api.js'

export default function folderController () {
  return {
    toggleSelected (event) {
      new Dispatch(this.$root)
        .dispatch('toggle-selected', { nodeId: this.$root.dataset.nodeId, checked: event.target.checked } )
    },

    init () {
      this.interactable = new Interactable(this.$refs.dragHandle, { minTouchDragDelta: 9999 })
        .on('click', (e) => { window.location.href = `/node/${this.$root.dataset.nodeId}` })
        .on('tap', (e) => { window.location.href = `/node/${this.$root.dataset.nodeId}` })
        .on('longtap', (e, provider) => { provider.startTouchDrag() })
        .on('mousedragstart', (e) => { this.onDragStart(e) })
        .on('mousedrag', (e) => { this.onDrag(e) })
        .on('mousedragend', (e) => { this.onDragEnd(e) })
        .on('touchdragstart', (e) => { this.onDragStart(e) })
        .on('touchdrag', (e) => { this.onDrag(e) })
        .on('touchdragend', (e) => { this.onDragEnd(e) })
        .on('dragenter', (e) => { this.$root.classList.add('hovered','bg-cyan-200')})
        .on('dragleave', (e) => { this.$root.classList.remove('hovered', 'bg-cyan-200') })
        .on('drop', (e) => { this.onDrop(e) })
    },

    onDragStart (e) {
      console.log('dragstart')
      this.$root.classList.add('dragging','bg-gray-200')
      this.dragImage = createDraggableImage(this.$root)
      this.dragImage.style.position = 'absolute'
      this.dragImage.style.top = '0px'
      this.dragImage.style.left = '0px'
      this.dragImage.style.transform = `translate(-99999px, -99999px)`
      document.body.appendChild(this.dragImage)
    },

    onDrag (e) {
      console.log('ondrag')
      this.moveDragImage(e)
    },

    onDragEnd (e) {
      this.$root.classList.remove('dragging', 'bg-gray-200')
      document.body.removeChild(this.dragImage)
    },

    onDrop (e) {
      this.$root.classList.remove('hovered', 'bg-cyan-200')
      let nodeId = e.relatedInteraction.target.dataset.nodeId
      if (nodeId) {
        let parentId = this.$root.dataset.nodeId
        App.api.updateNode(nodeId, { parent_id: parentId })
          .then((response) => { App.dispatch('node-moved', { nodeId }) })
          .catch((error) => { App.alert(error) })
      }
    },

    moveDragImage (e) {
      requestAnimationFrame (() => {
        if (this.dragImage) {
          let scrollXOffset = window.scrollX
          let scrollYOffset = window.scrollY
          let rects = this.$root.getBoundingClientRect()
          this.dragImage.style.pointerEvents = 'none'
          this.dragImage.style.zIndex = '99999'
          let x = Math.round(rects.left + scrollXOffset + e.deltaX)
          let y = Math.round(rects.top + scrollYOffset + e.deltaY)
          this.dragImage.style.transform = `translate(${x}px, ${y}px)`
        }
      })
    }
  }
}
