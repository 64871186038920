import { MouseInteraction } from './mouse_interaction.js'

export class MouseInteractable {
  constructor (el, opts={}) {
    this.target = el
    this.opts = opts
    this.interactions = []
    this.callbacks = []

    this.maxClickDelta = this.fetchOption('maxClickDelta', 5)
    this.maxClickDuration = this.fetchOption('maxClickDuration', 500)
    this.maxDoubleClickDuration = this.fetchOption('maxDoubleClickDuration', 500)
    this.longClickDuration = this.fetchOption('longClickDuration', 800)
    this.minMouseDragDelta = this.fetchOption('minMouseDragDelta', this.maxClickDelta)
  }

  on (eventName, callback) {
    this.callbacks[eventName] = callback

    // lazy add event handlers
    switch (eventName) {
      case 'click':
        this.listenToClick()
        break
      case 'dblclick':
        this.listenToDblClick()
        break
      case 'longclick':
        this.listenToLongClick()
        break
      case 'mousedragstart':
        this.listenToMouseDragStart()
        break
      case 'mousedrag':
        this.listenToMouseDrag()
        break
      case 'mousedragend':
        this.listenToMouseDragEnd()
        break
    }

    return this
  }

  listenToClick () {
    if (this.subscribedToClick) { return }
    this.subscribedToClick = true

    this.target.addEventListener('mousedown', this.onMouseDown)
    // Firefox doesn't fire this event on document
    document.body.addEventListener('mouseleave', this.onMouseLeave)
    this.target.addEventListener('click', (e) => { e.preventDefault() })
  }

  listenToDblClick () {
    if (this.subscribedToDblClick) { return }
    this.subscribedToDblClick = true

    this.listenToClick()
  }

  listenToLongClick () {
    if (this.subscribedToLongClick) { return }
    this.subscribedToLongClick = true

    this.listenToClick()
  }

  listenToMouseDragStart () {
    if (this.subscribedToMouseDragStart) { return }
    this.subscribedToMouseDragStart = true

    this.listenToClick()
    // this.target.addEventListener('dragstart', (e) => { console.log('dragstart native'); e.preventDefault() })
  }

  listenToMouseDrag () {
    if (this.subscribedToMouseDrag) { return }
    this.subscribedToMouseDrag = true

    this.listenToMouseDragStart()
  }

  listenToMouseDragEnd () {
    if (this.subscribedToMouseDragEnd) { return }
    this.subscribedToMouseDragEnd = true

    this.listenToMouseDragStart()
  }

  onMouseDown = (event) => {
    if (event.button !== 0) { return } // only handle left clicks

    let interaction = new MouseInteraction(this.target, event)
    this.interactions.unshift(interaction)

    // listen for drag events
    if (this.subscribedToMouseDragStart) {
      this.target.ownerDocument.addEventListener('mousemove', this.onMouseMove)
    }

    // listen for mouse up events on document
    if (this.subscribedToClick) {
      this.target.ownerDocument.addEventListener('mouseup', this.onMouseUp)
    }

    if (this.subscribedToLongClick) {
      this.longClickTimer = setTimeout(() => {
        this.fire('longclick', { ...interaction })
      }, this.longClickDuration)
    }
  }

  onMouseMove = (event) => {
    this.interactions[0].addEvent(event)

    if (this.subscribedToMouseDragStart && !this.mouseDragStarted && this.wasMouseDragged(this.interactions[0])) {
      if (this.subscribedToLongClick) { clearTimeout(this.longClickTimer) }
      this.mouseDragStarted = true
      this.fire('mousedragstart', { ...this.interactions[0] })
      this.dispatch('interactable-dragstart', { ...this.interactions[0] })
    } else if (this.subscribedToMouseDrag && this.mouseDragStarted) {
      this.fire('mousedrag', { ...this.interactions[0] })
      this.dispatch('interactable-drag', { ...this.interactions[0] })
    }
  }

  onMouseUp = (event) => {
    if (this.subscribedToMouseDrag) {
      document.removeEventListener('mousemove', this.onMouseMove)
    }

    if (this.subscribedToMouseDrag) {
      document.removeEventListener('mouseup', this.onMouseUp)
    }

    if (!this.interactions[0]) { return }

    this.interactions[0].addFinalEvent(event)

    if (this.subscribedToLongClick) { clearTimeout(this.longClickTimer) }

    if (this.subscribedToMouseDragEnd && this.mouseDragStarted) {
      this.fire('mousedragend', { ...this.interactions[0] })
      this.dispatch('interactable-dragend', { ...this.interactions[0] })
    } else if (this.subscribedToDblClick && !this.mouseDragStarted && this.wasDblClicked(this.interactions)) {
      this.fire('dblclick', { ...this.interactions[0] })
    } else if (this.subscribedToClick && !this.mouseDragStarted && this.wasClicked(this.interactions[0])) {
      this.fire('click', { ...this.interactions[0] })
    }

    if (this.mouseDragStarted) { this.mouseDragStarted = false }
  }

  onMouseLeave = (event) => {
    this.onMouseUp(event)
  }

  wasClicked (interaction){
    if (!interaction) { return false }
    if (interaction.delta > this.maxClickDelta) { return false }
    if (interaction.duration > this.maxClickDuration) { return false }

    return true
  }

  wasDblClicked (interactions) {
    if (!this.wasClicked(interactions[0])) { return false }
    if (!this.wasClicked(interactions[1])) { return false }

    let timeBetweenClicks = interactions[0].start - (interactions[1].start + interactions[1].duration)
    return timeBetweenClicks < this.maxDoubleClickDuration
  }

  wasMouseDragged (interaction) {
    if (!interaction) { return false }

    return interaction.delta >= this.minMouseDragDelta
  }

  fire (eventName, interaction) {
    let callback = this.callbacks[eventName]
    if (callback) {
      interaction.eventName = eventName
      callback(interaction, this)
    }
  }

  dispatch (eventName, interaction) {
    document.dispatchEvent(new CustomEvent(eventName, { detail: interaction }))
  }

  fetchOption (option, defaultValue) {
    if (this.opts[option] === undefined) { return defaultValue }

    return this.opts[option]
  }
}
