import Sortable from 'sortablejs'
import Alpine from '../alpine.js'

Alpine.data('task_sorting', () => ({
  sortable: null,
  init () {
    this.sortable = new Sortable(this.$refs.sortable, {
      handle: '.drag-handle',
      ghostClass: 'sort-ghost',
      dragClass: 'sort-drag',
      dataIdAttr: 'data-task-id',
      onUpdate: (event) => { this.onUpdate(event) }
    })
  },
  onUpdate (event) {
    let taskId = event.item.dataset.taskId
    let array = this.sortable.toArray()
    // alpine requires a template for a v-for so there is an extra
    // child element. Maybe I can ignore that somehow in the sortable
    // constructor?
    array.shift()
    let insertAfterItemId = array[event.newDraggableIndex - 1]
    App.api.sortTask(taskId, { insert_after_id: insertAfterItemId })
      .catch((error) => { console.log(error) })
  }
}))
