export default function createDraggableImage (src) {
  let img = src.cloneNode(true)

  function cleanAttributes (dest) {
    dest.removeAttribute('id')
    dest.removeAttribute('class')
    dest.removeAttribute('style')
    dest.removeAttribute('draggable')
  }

  function setStyles (src, dest) {
    let computedStyles = getComputedStyle(src)

    // Ignore transitions
    for (var i=0; i < computedStyles.length; i++) {
      var style = computedStyles[i]
      if (style.indexOf('transition') < 0) {
        dest.style[style] = computedStyles[style]
      }
    }
    dest.style.pointerEvents = 'none'
    dest.style.opacity = '0.8'

    // continue to set styles on child nodes
    for (var i=0; i < src.children.length; i++) {
      setStyles(src.children[i], dest.children[i])
    }
  }

  cleanAttributes(img)
  setStyles(src, img)

  return img
}
